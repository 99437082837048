<template>
        <main-template>
            <template v-slot:headerLottieDark>
                <dotlottie-player :src="getDarkLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:headerLottieLight>
                <dotlottie-player :src="getLightLogo()"
                                  background="transparent" speed="1"  style="width: 450px; height: 80px" direction="1" playMode="normal"  no-autoplay></dotlottie-player>
            </template>
            <template v-slot:header>QA Management</template>
            <template v-slot="slotProps">
            <fetch-siren-root ref="sirenRoot" :linkName='linkName' :link="link" @responseLoaded="getDetails">

                <div slot-scope="{ response }" class="flex flex-grow overflow-hidden">

                        <div class="flex w-full overflow-hidden">
                            <div class="p-2 h-full">
                                <qa-navigation :links="slotProps.links"></qa-navigation>
                            </div>
                            <div class="flex w-full h-full p-2 overflow-hidden">
                                <div class="rounded-xl bg-v3-base-500 w-full h-full margin-10 dark:bg-v3-gray-900 overflow-hidden">
                                    <loading v-if="!response"></loading>
                                    <template v-else>
                                        <div class="p-2 overflow-y-auto h-full">
                                            <qa-tracker-pagination :entities="details" :response="response">
                                                <template v-slot:additional-buttons>
                                                    <template class="mr-2" v-for="(action, index) in details.actions.filter(action => action.name === 'add-categories-and-plots' || action.name === 'update-tracker')">
                                                        <add-categories-and-plots-to-qa-tracker-form v-if="action[0] === 'add-categories-and-plots'" :action="action[1]"
                                                             additionalButtonClasses="my-2 w-full"
                                                             class="mr-2"
                                                             :actionKey="action[0]"
                                                             :redirectOnSuccess="true"
                                                             :on-success-path="$route.fullPath"
                                                        ></add-categories-and-plots-to-qa-tracker-form>
                                                        <form-action v-else :action="action[1]"
                                                             additionalButtonClasses="my-2 w-full"
                                                             class="mr-2"
                                                             :actionKey="action[0]"
                                                             :redirectOnSuccess="true"
                                                             :on-success-path="$route.fullPath"
                                                        ></form-action>
                                                    </template>
                                                </template>
                                            </qa-tracker-pagination>

                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                </div>
            </fetch-siren-root>
            </template>
        </main-template>
</template>

<script>
import FetchSirenRoot from '@/components/FetchSirenRoot';
import Loading from '@/components/Loading';
import MainTemplate from '@/v3/templates/MainTemplate';
import {mapGetters} from "vuex";
import Siren from "super-siren";
import QaNavigation from "@/v3/components/navigation/QaNavigation.vue";
import QaTrackerPagination from "@/v3/components/pagination/QaTrackerPagination.vue";
import CreatePaymentVoucherForm from "@/v3/components/forms/CreatePaymentVoucherForm.vue";
import FormAction from "@/v3/components/forms/FormAction.vue";
import AddCategoriesAndPlotsToQaTrackerForm from "@/v3/components/forms/AddCategoriesAndPlotsToQaTrackerForm.vue";

export default {
    inject: ["$validator"],
    components: {
        AddCategoriesAndPlotsToQaTrackerForm,
        FormAction, CreatePaymentVoucherForm,
        QaTrackerPagination,
        QaNavigation,
        FetchSirenRoot,
        Loading,
        MainTemplate,

    },
    data() {
        return {
            slotProps: null,
            details: null,
            detailsOpen: false,
            loadingDetails: false
        }
    },
    computed: {
        ...mapGetters({
            accessToken: "getAccessToken",
            user: "getUser"
        }),
        linkName() {
            return null;
        },
        link(){
            // if(Object.keys(this.$route.query).length === 0){
            //     return '/orders';
            // }
            return this.$route.fullPath.replace('/v3','')
        }

    },
    mounted() {
        let follow = Siren.Helper.follow;
    },
    methods: {
        getDetails(response){
            this.details = response.body;
        },
        getDarkLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=qa_dark"
        },
        getLightLogo()
        {
            return process.env.VUE_APP_API_URL + "/api/json/lottie?type=qa_light"
        }
    }
}
</script>

